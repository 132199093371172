import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const Column = makeShortcode("Column");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Video = makeShortcode("Video");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "decentralized-science",
      "style": {
        "position": "relative"
      }
    }}>{`Decentralized Science`}<a parentName="h1" {...{
        "href": "#decentralized-science",
        "aria-label": "decentralized science permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <blockquote>
      <p parentName="blockquote">{`"The search for knowledge is not nourished by certainty: it is nourished by a radical absence of certainty. Thanks to the acute awareness of our ignorance, we are open to doubt and can continue to learn and learn better. This has always been the strength of scientific thinking - thinking born of curiosity, revolt, change. There is no cardinal or final fixed point, philosophical or methodological, with which to anchor the adventure of knowledge." - `}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=95MscWzdNi4&list=PL_NAkUK835PWbclYgtIJ07aXSPx-cWper&index=6"
        }}>{`Carlo Rovelli`}</a></p>
    </blockquote>
    <p>{`Science - from the Latin `}<em parentName="p">{`scire`}</em>{` for 'to know' - is mostly about the satisfaction of curiosity. As such, elements of it have always been decentralized: everyone is curious about something, and everyone can develop mechanisms to satisfy their curiosity.`}</p>
    <p>{`However, some curiosities are so curious that they call out to be shared with others. Indeed, the fact that we can share anything at all is, in itself, most curious. It's really something of a miracle when you consider it, and so the sharing of stuff we have come to know raises a great deal of difficulty.`}</p>
    <p>{`DeSci is, in some part, about co-creating knowledge and then sharing it wisely, and then about how wise sharing relates to the activity of doing science itself. That is, this guild is primarily concerned with how we can: `}</p>
    <Process mdxType="Process">
      <p>{`Figure out collectively what curiosities are most worth funding`}</p>
      <p>{`Better fund people to investigate such curiosities`}</p>
      <p>{`Incentivise the honest and full investigation of such curiosities`}</p>
      <p>{`Enhance a diversity of thinking and methodology (through inclusive collaborations with diverse groups of people)`}</p>
      <Box mdxType="Box">
        <p>{`Share the results of everyone's investigations in a way which is`}</p>
        <ul>
          <li parentName="ul">{`maximally accessible and inclusive`}</li>
          <li parentName="ul">{`appropriately rewarding for the curious people doing the work`}</li>
          <li parentName="ul">{`efficient, such that we avoid unnecessary duplication of effort`}</li>
          <li parentName="ul">{`inspiring, so that sharing isn't just efficient but sparks or catalyzes further and more diverse kinds of curious investigations`}</li>
        </ul>
      </Box>
    </Process>
    <p>{`You'll notice that points 3 and 4 takes us into the realm of the activity of science and how better discovery, curation, funding and publishing mechanisms might advance our collective abilities to do "good" science. Of course, just what constitutes "good" science is also a curious question...`}</p>
    <h2 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}>{`Background`}<a parentName="h2" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We invite you to go through an introduction written by Sarah Hamburg, who has so generously lead this guild in our explorations so far, and the DeSci wiki written and curated by Jocelynn Pearl.`}</p>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`What is Decentralised Science?`}</strong></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://future.a16z.com/what-is-decentralized-science-aka-desci/" mdxType="Button">
    Investigate
          </Button>
        </Aligner>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`DeSci Wiki`}</strong></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://docs.google.com/document/d/1aQC6zn-eXflSmpts0XGE7CawbUEHwnL6o-OFXO52PTc/edit#" mdxType="Button">
    Together
          </Button>
        </Aligner>
      </Box>
    </Column>
    <h2 {...{
      "id": "principia",
      "style": {
        "position": "relative"
      }
    }}>{`Principia`}<a parentName="h2" {...{
        "href": "#principia",
        "aria-label": "principia permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Uur hypothesis is: `}<strong parentName="p">{`simple pairs of principles can lead to generative shared structures`}</strong>{`. `}</p>
    <p>{`In `}<a parentName="p" {...{
        "href": "/start/principled-people"
      }}>{`Kernel`}</a>{`, this takes the form of: (i) `}<strong parentName="p">{`do no harm`}</strong>{` (a summary of our web3 principles) and (ii) `}<strong parentName="p">{`play, infinitely`}</strong>{` (a summary of the personal principles). These two balance each other out and prevent irresponsibility, while also steering clear of prescriptive dogma. `}</p>
    <p>{`In `}<a parentName="p" {...{
        "href": "/learn/module-1/understanding-ethereum/#kaizen-and-the-principle-of-subtraction"
      }}>{`Ethereum`}</a>{`, we have (i) `}<strong parentName="p">{`kaizen`}</strong>{` and (ii) `}<strong parentName="p">{`subtraction`}</strong>{`, which emphasises accretive improvements without the kind of toxic pressure such iterative structures can have on individuals (as seen in Japan). `}</p>
    <p>{`In Celo, we have (i) `}<strong parentName="p">{`unique purpose`}</strong>{` and (ii) `}<strong parentName="p">{`connectedness`}</strong>{`, which grants us all a singular and useful role while grounding and contextualizing that in the communities with whom we participate.`}</p>
    <p>{`In the field of "science", there is a similar tension between the idea that science ought to be done for "good" and that science ought to be done for its own sake, to satisfy our curiosity (often the most unexpected innovations come from this kind of work). This tension serves as fertile ground for similarly balanced and complementary principles to do with open access and just compensation. So, let us state a possible pair of principles for DeSci:`}</p>
    <Process mdxType="Process">
      <p>{`Free access`}</p>
      <p>{`Fair reward`}</p>
    </Process>
    <p>{`Importantly, accessibility and reward need not be the only two principles for DeSci. However, this simple dyad gives us a guiding structure through which we can discuss most of the topics in which this guild is interested: open publishing, reproducibility, interpretability, distillation, rewards, recognition, accretion, visualization and more. `}</p>
    <p>{`The first principle centres largely around issues of publication and dissemination; the second around new economic patterns which may prevent the siphoning of public money by private journals and other institutions by routing it more directly from beneficiaries to innovators. Importantly, this pair is not reacting against a "broken" system, but doing something more along the lines of martial arts like judo. We don't prevent things by force, but with the sort of creativity that can use an opponent's power to redirect energy in more useful ways, in this case by programming value to re-cognize innovation.`}</p>
    <h2 {...{
      "id": "details",
      "style": {
        "position": "relative"
      }
    }}>{`Details`}<a parentName="h2" {...{
        "href": "#details",
        "aria-label": "details permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Let's dive a little deeper into our principled pair in order to explore some of the nuance associated with each principle:`}</p>
    <h3 {...{
      "id": "free-access",
      "style": {
        "position": "relative"
      }
    }}>{`Free Access`}<a parentName="h3" {...{
        "href": "#free-access",
        "aria-label": "free access permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Ideas are best when shared.`}</strong></p>
        <p>{`Our deepest insecurity is not that our ideas will be stolen, it is that they will be `}<em parentName="p">{`improved`}</em>{`. But this is exactly the goal of science: constant improvement of what we know and what we can know in the face of an uncertain universe. When your ideas are improved, it is proof you are a part of something bigger than yourself: the hallmark of self-actualizing activity. Whether you're working for "good" or for innate curiosity, keeping that front of mind will help overcome egotistic concern. The flip side - that some ideas are 'too dangerous' to be widely shared - is simply `}<a parentName="p" {...{
            "href": "/learn/module-5/prosocial-value/#dark-patterns"
          }}>{`not true`}</a>{`.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Provenance is most readily established from the start.`}</strong></p>
        <p>{`When we combine content addressabilitty with a "network of timestamp servers" (Satoshi's wording), we have a unique mechanism for both establishing provenance in an immutable fashion and rewarding people fairly based on the agreed order of the appearance of relevant data and its concomitant: the accumulation of share knowledge.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Free access necessitates collaboration.`}</strong>{` `}</p>
        <p>{`The word "`}<a parentName="p" {...{
            "href": "/learn/module-3/freedom"
          }}>{`free`}</a>{`" has a double directedness we consider regularly in Kernel. This means there is not just a financial/incentive aspect to this principle, but an ethical one. This principle also makes clear why we write about `}<a parentName="p" {...{
            "href": "/learn/module-3/freedom/#free-software"
          }}>{`freedom as enacted mutually and as a means to greater ends`}</a>{`, beyond the liberty of individuals. This interpretation of the word is critical, not just for ethical reasons, but because interdependence enables not just collaboration, but cross-disciplinary collaboration. DeSci requires scientists, curators, reviewers, editors, publishers, promoters, investors, lay readers, and many more. When you make work available to all, they imbue it with the `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=cuBpOXGLn_o&t=4013s"
          }}>{`value of their unique attention`}</a>{`.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Science is collective`}</strong></p>
        <p>{`Which we also mean in the sense of accretive. We do not deny the value of individual brilliance. We simply know that the best work is done by individuals who are conscious of 'something greater' to which their work relates. In other words, DeSci requires a whole `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=77mbz2HbY5Y"
          }}>{`scenius`}</a>{`.`}</p>
      </Box>
    </Process>
    <h3 {...{
      "id": "fair-reward",
      "style": {
        "position": "relative"
      }
    }}>{`Fair Reward`}<a parentName="h3" {...{
        "href": "#fair-reward",
        "aria-label": "fair reward permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Rewards are not only financial.`}</strong>{` `}</p>
        <p>{`Though they must include some economic element, we can be conscious of - and use to our advantage - the many ways in which are brains have evolved to experience and process 'rewards'. Daniel Friedman makes the point that these principles can be looked at through epistemic, social, pragmatic, and financial lenses, all of which will provide relevant information. `}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Fairness is contextual.`}</strong></p>
        <p>{`In AI circles, this is often talked about under the topic of "alignment", which can perhaps be maintained by ensuring there is a "human-in-the-loop". Incentive systems can be similarly powerful, but there are some differences highlighted by the different lenses above. In particular, incentive structures which `}<a parentName="p" {...{
            "href": "https://youtu.be/-FMKCMAUQIg?t=1473"
          }}>{`work in the absence`}</a>{` of human input are an `}<a parentName="p" {...{
            "href": "https://observablehq.com/@andytudhope/embedded-discover"
          }}>{`important socio-political`}</a>{` (and game-theoretic) tool. However, they need to be responsive and flexible, such that they `}<a parentName="p" {...{
            "href": "/learn/module-3/remember/#selecting-the-trail"
          }}>{`adapt to human attention and curiousity`}</a>{` in order to achieve the epistemic goals of DeSci.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Scientists are not a static or exclusive group.`}</strong></p>
        <p>{`Anyone who seeks to know may be considered a scientist, though that knowledge is always tested `}<a parentName="p" {...{
            "href": "/learn/module-metta/relating-kernel"
          }}>{`in relationship with others`}</a>{` and by virtue of replicable, falsifiable experiments.`}</p>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Rewards are a means: `}<a parentName="strong" {...{
              "href": "https://youtu.be/kffo3pxNO7c?t=466"
            }}>{`harmonious`}</a>{` understanding is the goal.`}</strong>{` `}</p>
        <p>{`This entire principle is dynamic, contextual, and constantly unfolding. The underlying drive to reward people fairly for the work they do, and to ensure that such work `}<a parentName="p" {...{
            "href": "/learn/module-4/governance/#anarchy"
          }}>{`helps us help each other`}</a>{`, is to find dynamic harmony. Importantly, we're not looking for equilibrium (life itself is a far-from-equilibrium thermodynamic system), but we are looking for ways to go on playing mellifluously.`}</p>
      </Box>
    </Process>
    <h2 {...{
      "id": "kb7-recordings",
      "style": {
        "position": "relative"
      }
    }}>{`KB7 Recordings`}<a parentName="h2" {...{
        "href": "#kb7-recordings",
        "aria-label": "kb7 recordings permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Convo #1 - Metascience`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/3awkk_E-Qo8" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Convo #2 - Biotech`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/PmqYc-LCdVk" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Convo #3 - Open Intelligence`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/2U8pv_k0sa4" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "from-the-archives",
      "style": {
        "position": "relative"
      }
    }}>{`From the Archives`}<a parentName="h2" {...{
        "href": "#from-the-archives",
        "aria-label": "from the archives permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Science does not dispel magic, it reveals it and we are here together to ensure that continues to happen in a free, fair and accessible manner. Here you can find some of the previous conversations we've participated in.`}</p>
    <List mdxType="List">
      <Link to="https://open.spotify.com/episode/1DqZwQHp5VW7OgfaodeVzG?si=Zfd6jgWMR1yDtgmEZA0OPg&nd=1" mdxType="Link">
        <p>{`Decentralizing Life Sciences Research & Funding with Vincent Weisser, CPO at Molecule and Core Team Member at Vita DAO`}</p>
      </Link>
      <Link to="https://vimeo.com/639601420?1" mdxType="Link">
        <p>{`One of the first panels ever hosted on DeSci, with Kernel fellow Bianca Trovò and friends.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=gGGN7UZUu8c" mdxType="Link">
        <p>{`Making Ownership Radically Weird`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=X5lhIsd83vg" mdxType="Link">
        <p>{`Exploring DeSci Through The Lens of Public Goods and Impact DAOs`}</p>
      </Link>
      <Link to="https://www.figma.com/file/csdjejN8p89VGDXAuHSWSn/DeSci-Guild-B5%3A-Session-1?node-id=0%3A1" mdxType="Link">
        <p>{`A shared Figma board from the KB5 Guild`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      